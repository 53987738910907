<template>

    <div v-for="product in products" class="card-custom text-center container-fluid">

        <div class="row justify-content-evenly pt-5 align-items-center">

            <!-- CARD IMMAGINI -->

            <!-- legna di bosco -->
            <div v-if="product.id == 1" class="col-md-3 mb-5 d-flex justify-content-center">
                <div id="carouselLegnaBosco" class="carousel carousel_width slide carousel-fade d-flex align-items-center">

                    <!-- carosello -->
                    <div class="carousel-inner carousel-custom">
                        <div class="carousel-item active">
                            <img class="img-box-custom carousel_img_width" :src="product.img1">
                        </div>
                        <div class="carousel-item">
                            <img class="img-box-custom carousel_img_width" :src="product.img2">
                        </div>
                        <div class="carousel-item">
                            <img class="img-box-custom carousel_img_width" :src="product.img3">
                        </div>
                    </div>

                    <!-- bottoni avanti e indietro -->

                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselLegnaBosco" data-bs-slide="prev">
                        <span class=" colore-freccina">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselLegnaBosco" data-bs-slide="next">
                        <span class=" colore-freccina">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </span>
                    </button>

                </div>
            </div>

            <!-- legna di segheria -->
            <div v-if="product.id == 2"  class="col-md-3 mb-5 d-flex justify-content-center">
                <div id="carouselLegnaSegheria" class="carousel carousel_width slide carousel-fade d-flex align-items-center">

                    <!-- carosello -->
                    <div class="carousel-inner carousel-custom">
                        <div class="carousel-item active">
                            <img class="img-box-custom carousel_img_width" :src="product.img1">
                        </div>
                        <div class="carousel-item">
                            <img class="img-box-custom carousel_img_width" :src="product.img2">
                        </div>
                    </div>

                    <!-- bottoni avanti e indietro -->
                    
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselLegnaSegheria" data-bs-slide="prev">
                        <span class=" colore-freccina">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselLegnaSegheria" data-bs-slide="next">
                        <span class=" colore-freccina">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </span>
                    </button>
                
                </div>
            </div>

            <!-- legna di refle -->
            <div v-if="product.id == 3"  class="col-md-3 mb-5 d-flex justify-content-center">
                <div id="carouselRefile" class="carousel carousel_width slide carousel-fade d-flex align-items-center">

                    <!-- carosello -->
                    <div class="carousel-inner carousel-custom">
                        <div class="carousel-item active">
                            <img class="img-box-custom carousel_img_width" :src="product.img1">
                        </div>
                        <div class="carousel-item">
                            <img class="img-box-custom carousel_img_width" :src="product.img2">
                        </div>
                    </div>

                    <!-- bottoni avanti e indietro -->
                    
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselRefile" data-bs-slide="prev">
                        <span class=" colore-freccina">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselRefile" data-bs-slide="next">
                        <span class=" colore-freccina">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </span>
                    </button>
                
                </div>
            </div>

            <!-- FINE CARD IMMAGINI -->

            <!-- corpo della card -->
            <div class="col-md-5 scritta-custom d-flex align-items-center mb-5 justify-content-center">
                <div class="row pt-3 d-flex justify-content-center align-items-center">
                    <!-- titolo -->
                    <h3 class="mb-md-2 mb-lg-3 mb-xl-5 fw-bold titolo-pb-piccolo condizionamento-card-h1">{{product.titolo}}</h3>
                    <!-- descrizione -->
                    <div class="bordo-interno">
                        <p class="fw-semibold condizionamento-h3">{{ product.descrizione_1 }}</p>
                        <p class="fw-semibold condizionamento-h3">{{ product.descrizione_2 }}</p>
                        <!-- bottone per la vista del dettaglio -->
                        <router-link v-if="product.id != 3" href="*" class="btn btn-custom mb-3 fw-semibold" :to="{name:'productDetail', params:{id: product.id}}">vedi dettagli</router-link> 
                    </div>
                </div>
            </div>
        </div>
        
    </div>

</template>

<script>

export default{
    name: 'cards_products',

    //proprietà passate
    props: ['products'],
}

</script>

<style>




</style>