<template>
    <body class="bg-image">
        <!-- introduzione -->
        <div class="col-12 d-flex justify-content-center pt-5">
            <div class="text-center text-black mt-5 _100 d-flex justify-content-center align-items-center">
                <div class="title-custom text-black mt-3 pt-2 pb-2 ps-4 pe-4">
                    <h1 class="condizionamento-h1">STUFE</h1>
                    <h2 class="condizionamento-h2">Qui troverete tutte le informazioni necessarie per capire quale tipologia di prodotto e migliore per la vostra stufa</h2>
                </div>
            </div>
        </div>

        <!-- cards delle stufe -->
        <div class="col-12 ">
            <!-- cards_stufe -->
            <cards_stufe :stufe="stufe"/>
        </div> 
        
    </body>
    
</template>

<script>

//importiamo le componenti
import cards_stufe from '../components/cards_stufe.vue'

//importiamo le immagini delle stufe
import imgCaminetto from "../assets/stufe/caminetto.jpg"
import imgSpolert from "../assets/stufe/spolert.jpg"
import imgStufeRendimento from "../assets/stufe/stufe_ad_alto_rendimento.jpg"
import imgStufe from "../assets/stufe/stufe.jpg"


export default{
  name: 'InformazioniView',

  components: {cards_stufe},

  data(){
    return{
        //dati delle stufe
        stufe: [

            //CAMINETTO
            { id:1, tipologia:'LEGNA PER CAMINETTI', descrizione_1:'Il FAGGIO è il legno con i requisiti migliori per resa e potere calorico, ma ottimo si rivela anche il CARPINE BIANCO nella misura da 33cm o per inserti più grandi, da 50cm di lunghezza.', descrizione_2:'LA LEGNA DI SEGHERIA è particolarmente adatta con tavole di misura grande, dai 35cm ai 50cm nelle essenze miste di FAGGIO, FRASSINO, ROVERE e NOCE.', link:'https://it.wikipedia.org/wiki/Camino_(edilizia)', img:imgCaminetto},

            //SPOLER/TERMO CUCINE
            { id:2, tipologia:'LEGNA PER SPOLERT E TERMOCUCINE', descrizione_1:'La legna adatta per lo SPOLERT con struttura in ghisa è il FAGGIO, il FRASSINO, l’ACACIA e il CARPINE BIANCO, nelle misure da 25/33 cm di lunghezza.', descrizione_2:'Si adattano molto bene anche i RITAGLI DI SEGHERIA che sono perfetti perchè sempre asciutti tutti i mesi dell’ anno.', descrizione_3:'Per le TERMOCUCINE in uso per riscaldare l’acqua dei termosifoni, l’ambiente e cucinare le pietanze si consiglia LEGNA di BOSCO tipo FAGGIO, CARPINE BIANCO e soprattutto LEGNA DI SEGHERIA.', link:'https://it.wikipedia.org/wiki/Termocucina', img:imgSpolert},

            //STUFE 1
            { id:3, tipologia:'LEGNA PER STUFE AD ALTO RENDIMENTO', descrizione_1:'Per ottenere migliore rendimento ed efficienza, il tipo di legno consigliato per questi dispositivi è il FAGGIO sia spaccato tipo tronchetto, sia come ritaglio di segheria.', descrizione_2:' Consigliati anche il FRASSINO e il CARPINE BIANCO per la loro ottima resa.', link:'https://it.wikipedia.org/wiki/Stufa_a_legna', img:imgStufeRendimento},

            //STUFE 2
            { id:4, tipologia:'LEGNA PER STUFE', descrizione_1:'La legna scelta per questo tipo di struttura a riscaldamento domestico sono IL FAGGIO e il FRASSINO tipo REFILE con lunghezza media da 35 cm, molto stagionate ed essiccate.', link:'https://it.wikipedia.org/wiki/Stufa_a_legna', img:imgStufe},
            
        ]
    }
  }
}

</script>

<style>


/* titoli */
.title-custom{
    color: #705a0cff;
}

</style>