//importo le viste
import { createRouter, createWebHistory } from 'vue-router'
//viste della navbar
import HomeView from '../views/HomeView.vue'
import ProductsView from '../views/ProductsView.vue'
import LegnaInSacchiView from '../views/LegnaInSacchiView.vue'
import InformazioniView from '../views/InformazioniView.vue'
import ContactsView from '../views/ContactsView.vue'
//vista dei dettagli del prodotto
import ProductDetailView from '../views/ProductDetailView.vue'

const routes = [

  //HOME
  {
    path: '/',
    name: 'home',
    component: HomeView
  },

  //PRODOTTI
  {
    path: '/prodotti',
    name: 'prodotti',
    component: ProductsView
  },

  //LEGNA IN SACCHI
  {
    path: '/legna-in-sacchi',
    name: 'legna-in-sacchi',
    component: LegnaInSacchiView
  },

  //INFORMAZIONI
  {
    path: '/informazioni',
    name: 'informazioni',
    component: InformazioniView
  },

  //CONTATTI
  {
    path: '/contatti',
    name: 'contatti',
    component: ContactsView
  },

  //DETTAGLI dei PRODOTTI
  {
    path: '/product-detail/:id',
    name: 'productDetail',
    component: ProductDetailView
  }
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
