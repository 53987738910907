<template>
  
  <body class="bg-image text-center">

    <!-- ALTEZZA per mostrare l'immagine all'inizio -->
    <!-- <div class="altezza"></div> -->

    <!-- corpo con le CARDS DESCRITTIVE -->
    <div class="container-fluid altezza">

      <!-- L'AZIENDA -->
      <div class="row justify-content-evenly pt-5">
        <!-- carosello immagini azienda -->
        <div class="col-md-3 mb-5 d-flex justify-content-center">
          <div id="carouselExampleFade" class="carousel carousel_width slide carousel-fade d-flex align-items-center">

            <!-- carosello -->
            <div class="carousel-inner carousel-custom">
              <div class="carousel-item active">
                <img class="img-box-custom carousel_img_width" src="../assets/azienda/cartello_1.jpg">
              </div>
              <div class="carousel-item">
                <img class="img-box-custom carousel_img_width" src="../assets/azienda/cartello_2.jpg">
              </div>
              <div class="carousel-item">
                <img class="img-box-custom carousel_img_width" src="../assets/azienda/cartello_3.jpg">
              </div>
              <div class="carousel-item">
                <img class="img-box-custom carousel_img_width" src="../assets/azienda/cartello_4.jpg">
              </div>
            </div>

            <!-- bottoni avanti e indietro -->
            
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
              <span class=" colore-freccina">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
              <span class=" colore-freccina">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </span>
            </button>
          
          </div>
        </div>
        <!-- descrizione -->
        <div class="col-md-5 scritta-custom d-flex align-items-center mb-5">
          <div class="row pt-3">
            <h2 class="mb-md-2 mb-lg-3 mb-xl-5 fw-bold titolo-pb-piccolo condizionamento-card-h1">L'AZIENDA</h2>
            <div class="bordo-interno">
              <p class="fw-semibold condizionamento-h3">L’azienda è specializzata  nella vendita di <span class="fw-bolder">ritagli di segheria</span> di  varie essenze (FRASSINO, FAGGIO, ROVERE) e di diverse misure.<br>
              La <span class="fw-bolder">legna di bosco</span>, tipo tronchetto spaccato, è disponibile nelle misure da 25/33/50cm, nelle essenze di faggio, carpine, frassino e acacia.<br>
              La legna viene consegnata a domicilio, sfusa, in bancale o in sacchi da 15kg.<br>
              Stivata sotto il forno per pizzerie e ristoranti, consegnata pronta all’ uso.<br>
              La legna è offerta per il ritiro in magazzino in pallet e in SACCHI DA 15 KG.</p>
            </div>
          </div>
        </div>
      </div>

      <!-- DOVE SIAMO -->

      <!-- SCHERMO GRANDE -->
      <div class="row justify-content-evenly pt-5 invertitore-grande">
        <!-- descrizione -->
        <div class="col-md-5 scritta-custom d-flex align-items-center mb-5">
          <div class="row pt-3 justify-content-center align-items-center">
            <h3 class="mb-md-2 mb-lg-3 mb-xl-5 fw-bold titolo-pb-piccolo condizionamento-card-h1">DOVE SIAMO</h3>
            <div class="bordo-interno">
              <p class="fw-semibold condizionamento-h3">L’Azienda è situata in via Papa Giovanni XXIII  n. 48 a Corno di Rosazzo,  località Sant’ Andrat dello Judrio.<br>
              Se venite da <span class="fw-bolder">Manzano</span>, dovete prendere la SP29 e poi imboccare la SR356, poi girare a destra.<br>
              Se venite da <span class="fw-bolder">Cormons</span>, dovete seguire la strada SR356.</p>
              <p class="condizionamento-h3"><span class="fw-bolder">Indirizzo:  </span><i class="indirizzo-corsivo"><U>Via Giovanni XXIII Papa, 48, 33040 Corno di Rosazzo UD</U></i></p>
              <a class="btn btn-custom mb-3 fw-semibold" href="https://maps.app.goo.gl/HRKYY1rGRWecLY5T7" alt="link google maps" target="_blank">google maps</a>
            </div>
          </div>
        </div>
        <!-- screenshot luogo -->
        <div class="col-md-3 mb-5 d-flex align-items-center">
          <img class="img-box-custom img-width" src="../assets/google_maps_mappa.png" alt="">
        </div> 
      </div>

      <!-- SCHERMO PICCOLO -->
      <div class="row justify-content-evenly pt-5 invertitore-piccolo">
        <!-- screenshot luogo -->
        <div class="col-md-3 mb-5 d-flex align-items-center justify-content-center">
          <img class="img-box-custom img-width" src="../assets/google_maps_mappa.png" alt="">
        </div> 
        <!-- descrizione -->
        <div class="col-md-5 scritta-custom d-flex align-items-center mb-5">
          <div class="row pt-3 justify-content-center align-items-center">
            <h3 class="mb-md-2 mb-lg-3 mb-xl-5 fw-bold titolo-pb-piccolo condizionamento-card-h1">DOVE SIAMO</h3>
            <div class="bordo-interno">
              <p class="fw-semibold condizionamento-h3">L’Azienda è situata in via Papa Giovanni XXIII  n. 48 a Corno di Rosazzo,  località Sant’ Andrat dello Judrio.<br>
              Se venite da <span class="fw-bolder">Manzano</span>, dovete prendere la SP29 e poi imboccare la SR356, poi girare a destra.<br>
              Se venite da <span class="fw-bolder">Cormons</span>, dovete seguire la strada SR356.</p>
              <p class="condizionamento-h3"><span class="fw-bolder">Indirizzo:  </span><i class="indirizzo-corsivo"><U>Via Giovanni XXIII Papa, 48, 33040 Corno di Rosazzo UD</U></i></p>
              <a class="btn btn-custom mb-3 fw-semibold" href="https://maps.app.goo.gl/HRKYY1rGRWecLY5T7" alt="link google maps" target="_blank">google maps</a>
            </div>
          </div>
        </div>       
      </div>

      <!-- TRASPORTO -->
      <div class="row justify-content-evenly pt-5">

        <!-- carosello immagini trasporto -->
        <div class="col-md-3 mb-5 d-flex justify-content-center">
          <div id="carouselTrasporto" class="carousel carousel_width slide carousel-fade d-flex align-items-center">

            <!-- carosello -->
            <div class="carousel-inner carousel-custom">
              <div class="carousel-item active">
                <img class="img-box-custom carousel_img_width" src="../assets/trasporto/camion_laterale.jpg">
              </div>
              <div class="carousel-item">
                <img class="img-box-custom carousel_img_width" src="../assets/trasporto/camion_dietro.jpg">
              </div>
              <div class="carousel-item">
                <img class="img-box-custom carousel_img_width" src="../assets/trasporto/piccolo_1.jpg">
              </div>
              <div class="carousel-item">
                <img class="img-box-custom carousel_img_width" src="../assets/trasporto/piccolo_2.jpg">
              </div>
              <div class="carousel-item">
                <img class="img-box-custom carousel_img_width" src="../assets/trasporto/piccolo_3.jpg">
              </div>
            </div>

            <!-- bottoni avanti e indietro -->
            
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselTrasporto" data-bs-slide="prev">
              <span class=" colore-freccina">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselTrasporto" data-bs-slide="next">
              <span class=" colore-freccina">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </span>
            </button>
          
          </div>
        </div>

        <!-- descrizione -->
        <div class="col-md-5 scritta-custom d-flex align-items-center mb-5">
          <div class="row pt-3 justify-content-center align-items-center">
            <h3 class="mb-md-2 mb-lg-3 mb-xl-5 fw-bold titolo-pb-piccolo condizionamento-card-h1">TRASPORTO</h3>
            <div class="bordo-interno">
              <P class="fw-semibold condizionamento-h3">Il materiale viene consegnato a domicilio con camion ribaltabile posteriore, oppure con mezzo dotato di sponda idraulica per la consegna e posizionamento dei bancali di legna.</P>
            </div>
          </div>
        </div>
      </div>
      
    </div>

  </body>

</template>

<script>

export default{
  name: 'HomeView',
}

</script>

<style>

/* immagine di sfondo */
.bg-image{
  background-image: url('../assets/logo_telefono.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  min-width: 100%;
}
.altezza{
  padding-top: 70%;
}

/* CARTE IMMAGINE */
.img-box-custom{
  border-radius: 20%;
  height: auto;
  border: solid 3.5px black;
}
.img-width{
  width: 100%;
}
.carousel-custom{
  border-radius: 20%;
}
.carousel_img_width{
  width: 100%;
}
.colore-freccina{
  background-color: rgb(0,0,0,0.5);
  max-height: max-content;
  max-width: max-content;
  border-radius: 40px;
  padding: 5px;
  
}
.invertitore-grande{
  display: none;
}

/* CARTE SCRITTA */
.titolo-pb-piccolo{
  padding-bottom: 20px;
  
}
.scritta-custom{
  background-color:  rgba(136, 111, 24);
  border: solid 3.5px black;
  border-radius: 60px;
  width: 95%;
  padding: 20px 30px 20px 30px;
}
.bordo-interno{
  border: solid 3px black;
  border-radius: 60px;
  padding: 20px;
  background-color:  rgba(178, 153, 61, 0.793);
  
}
.indirizzo-corsivo{
  font-size: 15px;
}

/* bottone google maps */
.btn-custom{
  background-color: rgba(121, 98, 15, 0.793);
  width: max-content;
  border-radius: 20px;
  border:solid 3px rgb(112,90,12);
}
.btn-custom:hover{
  background-color:  rgb(112,90,12);
  color: black;
  border:solid 3px black;
}

@media (min-width: 300px) { 
  .scritta-custom{
    width: 90%;
  }
}

@media (min-width: 350px) { 
  .img-width{
    width: 50%;
  }
  .carousel_width{
    width: 50%;
  }
  .carousel_img_width{
    width: 100%;
  }
  .scritta-custom{
    width: 80%;
  }
}

@media (min-width: 450px){
  
  .altezza{
    padding-top: 50%;
  }
}

@media (min-width: 768px) { 
  .bg-image{
    background-image: url('../assets/logo_ufficiale.png');
  }
  .altezza{
    padding-top: 30%;
  }
  .titolo-pb-piccolo{
    padding-bottom: 0px;
  }
  .img-width{
    width: 100%;
  }
  .scritta-custom{
    width:50%;
  }
  .carousel_img_width{
    width: 100%;
  }
  .carousel_width{
    width: 100%;
  }
  .invertitore-grande{
    display: flex;
  }
  .invertitore-piccolo{
    display: none;
  }
 
}

</style>
